#navigation-body {
    height: 8rem;
    justify-content: space-between;
    align-items: center;
    position: fixed;
}

#navigation-logo {
    background-color: transparent;
}

#navigation-logo img {
    width: 5rem;
}

#navigation-links {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: auto;
    color: white !important;
}

#navigation-links a {
    display: inline-flex;
    width: max-content;
}

#navigation-burger {
    display: none;
}

#navigation-mobile-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    transition: .3s ease-in-out;
    color: white;
}

#navigation-mobile-menu:hover {
    cursor: pointer;
    scale: 1.1;
}


@media only screen and (max-width: 800px) {

    #navigation-dropdown-mobile-menu-outer {
        width: 60vw !important;
    }

}

@media only screen and (max-width: 1200px) {

    #navigation-dropdown-mobile-menu-exit {
        transition: .3s ease-in-out;
    }

    #navigation-dropdown-mobile-menu-exit:hover {
        scale: 1.1;
        cursor: pointer;
    }

    #navigation-dropdown-mobile-menu-outer {
        height: 100vh;
        width: 40vw;
        background-color: white;
        position: fixed;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
    }

    #navigation-dropdown-mobile-menu-inner {
        height: 90%;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        flex-direction: column;

    }

    #navigation-burger {
        display: flex;
    }

    #navigation-links {
        display: none;
    }

    #navigation-mobile-links-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        flex-grow: 1;
    }

    #navigation-dropdown-mobile-menu-logo-container img {
        height: 6rem;
    }

    #navigation-dropdown-mobile-menu-logo-container {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
    }

    #navigation-dropdown-mobile-menu-icon-container {
        width: 100%;
        display: flex;
        justify-content: right;
        padding: 1rem 0;
        flex-grow: 1;
        color: #FF8317;
    }


}