#footer-outer {
    flex-direction: column;
}

#footer-hero {
    padding: 120px 0;
    background-image: url('./foot.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

#footer-hero-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#footer-inner-body-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 120px;
}

#footer-inner-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
}

#footer-content-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
}

#footer-button{
    padding:1rem 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
}

#footer-button:hover{
    cursor: pointer;
}

#footer-content {
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

#footer-content-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#footer-content img{
    display:none;
}

@media only screen and (max-width: 1000px) {

    #footer-hero {
        gap: 10px;
    }

    #footer-content {
        flex-direction: column;
        gap: 48px;
    }

    #footer-inner-body {
        padding: 60px 0;
    }

    #footer-inner-body-content {
        gap: 60px;
    }

    #footer-content-title {
        gap: 10px;
        justify-content: center;
        align-items: center;
    }

    #footer-content-links{
        align-items: center;
        justify-content: center;
    }

    #footer-content img{
        display:block;
        width:10rem;
    }

}