#header-body-outer {
    height: 100vh;
    background-image: url("./backgroundheader.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#header-body-inner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    gap: 10px;
}

a {
    color: white;
}


@media only screen and (max-width: 1000px) {
    #header-body-outer {
        background-image: url("./mobilebackgroundheader.jpg");
        background-size: cover;
        background-position: center;
        background-attachment: scroll;
    }
}