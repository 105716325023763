.hero-body-outer {
    height: auto;
    padding: 120px 0;
}

.hero-body-inner {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    gap: 60px;
}

#hero-button {
    padding: 1rem 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
}

#hero-button:hover {
    cursor: pointer;
}

.hero-body-img img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.hero-body-img {

    width: 100%;
}

.hero-body-content {
    flex-wrap: wrap;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    gap: 36px;
}

.hero-body-content ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

@media only screen and (max-width: 1000px) {

    .hero-body-inner {
        flex-direction: column;
        align-items: center;
    }

    .hero-body-outer {
        padding: 60px 0;
    }

    .hero-body-content {
        gap: 24px;
        justify-content: center;
        align-items: center;
    }

    .hero-body-content {
        text-align: center;
    }

    .hero-body-content ul {
        list-style: none;
        padding-left: 0;
    }

    .hero-body-content ul li {
        font-weight: 700;
    }

}