.partner-outer{
    height:auto;
    padding:120px 0;
}

.partner-inner{
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:36px;
}

.partner-inner img{
    width:10rem;
}

.partner-inner-logos{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1000px) {

    .partner-outer{
        padding:60px 0;
    }    

    .partner-inner{
        gap:60px;
        text-align: center;
    }

    .partner-inner-logos{
        flex-direction: column;
        gap:36px;
    }

}