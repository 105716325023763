.button{
    padding:1rem 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
}

.button:hover{
    cursor: pointer;
}