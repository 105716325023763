body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
}

* {
  margin: 0;
  scroll-behavior: smooth !important;
}

h2 {
  margin: 0;
}

.hdr-1 {
  font-size: 72px;
  font-weight: 700;
}

.hdr-2 {
  letter-spacing: -2px;
  font-size: 48px;
  font-weight: 700;
}

.hdr-6 {
  font-size: 36px;
  font-weight: 500;
}

.hdr-3 {
  letter-spacing: -2px;
  font-size: 36px;
  font-weight: 700;
}

.hdr-4 {
  font-size: 24px;
  font-weight: 700;
}

.hdr-5 {
  line-height: 1.5rem;
  font-size: 16px;
  font-weight: 700;
}

.sbt-1 {
  font-size: 36px;
  font-weight: 500;
  line-height: 2.5rem;
}

.sbt-2 {
  font-size: 16px;
  font-weight: 500;
}

.sbt-4 {
  font-size: 12px;
  font-weight: 500;
}

.sbt-3 {
  font-size: 24px;
  font-weight: 500;
}

.bg-black {
  background-color: #1A1C23;
}

.bg-orange {
  background-color: #FF8317;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-dark-gray {
  background-color: #54555A;
}

.bg-light-gray {
  background-color: #ececec;
}

.black {
  color: #1A1C23;
}

.orange {
  color: #FF8317 !important;
}

.white {
  color: #FFFFFF !important;
}

.dark-gray {
  color: #54555A;
}

.light-gray {
  color: #848485;
}

.bg-green {
  background-color:rgb(33, 174, 63);
}

a {
  text-decoration: none;
}

.inner {
  width: 90vw;
  display: flex;
}

span {
  font-style: italic;
  color: #FF8317;
}

.outer {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.line {
  background-color: white;
  height: 2px;
  width: 100%;
}

.underline {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropshadow {
  -webkit-box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.51);
  box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.51);
}

.hoverable:hover {
  transition: ease-in-out .3s !important;
  scale: 1.1 !important;
}

@media only screen and (max-width: 1000px) {
  
  .hdr-1 {
    font-size: 48px;
  }

  .sbt-2 {
    line-height: 24px;
    font-size: 15px;
  }

}