.testimonial-outer{
    padding:120px 0;
}
.testimonial-inner{
    flex-direction: column;
    gap:36px;
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    .testimonial-outer{
        padding:60px 0;
    }
}