#contact{
    background-color: white;
    padding: 120px 0;
}

#contact-body-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

#contact-content form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

#contact-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.contact-content-divs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.contact-content-divs input {
    height: 3rem;
    color: rgb(170, 170, 170);
    text-align: center;
    outline: none;
    border-left: 0 black solid;
    border-right: 0 black solid;
    border-top: 0 black solid;
    border-bottom: 3px black solid;
    font-size: 20px;
    font-weight: 700;
}

input:hover {
    cursor:pointer;
}

select:hover {
    cursor:pointer;
}

.contact-content-divs input::placeholder {
    color: rgb(170, 170, 170);
}

select {
    height: 3.3rem;
    color: rgb(170, 170, 170);
    font-size: 20px;
    font-weight: 700;
    padding-left: 1rem;
    outline: 0;
    display: flex;
    justify-content: center;
    -ms-word-break: normal;
    word-break: normal;
    appearance: none;
    width: 16rem;
    border-radius: 0;
    border-bottom: 3px solid black !important;
    border: 0 solid black;
    background: url('./arrowdown.svg') no-repeat;
    background-size: 5%;
    background-position: right center;
}

#input-name {
    width: 12rem;
}

#input-email {
    width: 18rem;
}

#contact-button {
    padding: 1rem 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
    border: none;
}

#contact-button:hover {
    cursor: pointer;
}

#contact-success {
    color: rgb(33, 174, 63);
}


@media only screen and (max-width: 1142px) {
    .contact-content-divs {
        flex-direction: column;
        text-align: center;
    }
}